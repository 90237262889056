
import * as React from "react"
import { useState } from "react"
import { graphql, HeadFC, PageProps, HeadProps, } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactModal from "../components/contact-modal"
import Navbar from "../components/navbar"

export const query = graphql`
  query Index {
    site {
      siteMetadata {
        title
        description
        email
        demo1Url
      }
    }
  }
`

const Index = ({ data: { site: { siteMetadata: { email, demo1Url } } } }: PageProps<Queries.IndexQuery>) => {
  const [contactModalActive, setContactModalActive] = useState(false);

  const openContactModal = () => setContactModalActive(true);
  const closeContactModal = () => setContactModalActive(false);

  return (
    <Layout>
      <Navbar onContactClicked={openContactModal} />
      <section className="hero is-medium hero-background-color has-background-image display-font">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column">
                <h2 className="subtitle is-2 has-text-primary">
                  Low cost flat fee website for small businesses.
                  <br />
                  No hidden cost.
                </h2>
              </div>
            </div>
            <div className="columns">
              <div className="column is-narrow">
                <h1 className="title is-1 is-spaced has-text-dark">
                  ONLY
                  <br />
                  £20+VAT
                  <br />
                  Monthly
                </h1>
              </div>
              {/* <div className="column has-text-right">
                <StaticImage src='../images/bg_responsive.svg' alt='background image' width={500} />
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section id="benefits" className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="has-text-centered">
                <h2 className="title is-3 has-text-light">
                  Get a website to increase credibility of your brand and reach potential customers
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="section my-6">
        <div className="container">
          <div className="columns is-variable is-8">
            {[{
              icon: '/img/icon-domain.svg',
              title: 'Free Domain Name',
              content: 'Choose your own .co.uk domain name at no extra charge. Also includes free forwarding to your own email address.',
            }, {
              icon: '/img/icon-hassle.svg',
              title: 'No Hassle',
              content: 'Just tell us what you need, and we’ll build & manage everything for you. You won’t have to worry about a thing!',
            }, {
              icon: '/img/icon-cost.svg',
              title: 'No Upfront Cost',
              content: 'What you see is what you pay. Everything included. No hidden charges.',
            }, {
              icon: '/img/icon-flexible.svg',
              title: 'Flexible',
              content: 'Add links to social media & review site profiles, picture gallery, testimonials, plus more!',
            },].map(d => (
              <div className="column is-3 mb-6" key={d.title}>
                <div className="content">
                  <img src={d.icon} alt='icon' width={100} height={100} />
                  <h4 className="title is-3">
                    {d.title}
                  </h4>
                  <p className="is-size-5">
                    {d.content}
                  </p>
                </div>
              </div>
            ))
            }
          </div>
        </div>
      </section>

      {/* <section className="section has-text-centered">
        <StaticImage src='../images/bg_onthego.svg' alt='background' width={250} />
      </section> */}

      <section className="section my-6 slanted-bg">
        <div className="section-heading mb-3 has-text-centered display-font">
          <h3 className="title is-1">How does it work?</h3>
        </div>
        <div className="container">
          <div className="columns is-centered is-variable is-8">
            <div className="column is-narrow">
              <div className="content is-size-3-desktop is-size-4-touch has-text-grey">
                <ol>
                  {[
                    'Tell us about your business by completing a form',
                    'We send you a website proposal to review',
                    'We build the website for you',
                    'Start generating work!',
                  ].map(d => (
                    <li className="has-text-weight-semibold" key={d}>
                      {d}
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="demo" className="section my-6">
        <div className="container">
          <div className="columns is-centered is-variable is-8">
            <div className="column">
              <div className="content is-size-4 has-text-centered">
                <a className="button is-info is-rounded has-text-success is-large px-6 py-6 my-6 has-text-weight-bold" onClick={openContactModal}>
                  INTERESTED?
                  <br />
                  GET IN TOUCH NOW
                </a>
                <p className="has-text-dark">
                  Undecided? See an <a href={demo1Url} target="_blank" className="has-text-link is-underlined">example preview website here</a>
                </p>
                <a href={demo1Url} target="_blank">
                  <figure className="image">
                    <StaticImage src={'../images/demo_screen.png'} alt='demo screen' width={700} />
                  </figure>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section my-6">
        <div className="section-heading mb-3 has-text-centered display-font">
          <h3 className="title is-1">FAQ</h3>
        </div>
        <div className="container">
          <div className="columns is-centered is-variable is-8">
            <div className="column is-6">
              <div className="content is-size-5">
                {[{
                  q: 'What\'s included?',
                  a: { __html: 'A domain name of your choice that ends in .co.uk (subject to availability), a fully managed website, and an email address (e.g. info@your-domain-name.co.uk) that forwards all emails to your own email address.' },
                }, {
                  q: 'How much is it?',
                  a: { __html: 'Only flat fee £20 per month plus VAT. Billed monthly. Nothing else.' },
                }, {
                  q: 'What is the turnaround time?',
                  a: { __html: 'It usually takes 2 to 7 days after the specifications have been agreed.' },
                }, {
                  q: 'What will the website look like?',
                  a: { __html: 'Have a look at our <a href=' + demo1Url + ' target="_blank">demo website here</a>. You can add or remove sections as you see fit as part of the proposal.' },
                }, {
                  q: 'Does it work on mobile phones?',
                  a: { __html: 'Yes, all our websites are both mobile-friendly and computer-friendly.' },
                }, {
                  q: 'Can I include links to my Facebook page?',
                  a: { __html: 'Yes, you can link to all of your social media profiles such as Facebook, Instagram, Twitter, etc.' },
                }, {
                  q: 'Can I include links to my TrustPilot profile?',
                  a: { __html: 'Yes, you can link to all of your directory and review site profiles such as TripAdvisor, Checkatrade, MyBuilder, TrustPilot, RatedPeople, etc.' },
                }, {
                  q: 'Can I add pictures?',
                  a: { __html: 'Yes, you can add pictures (for example of your team, premises, or previous jobs) to the gallery section. We recommend having at least 3 but no more than 30 pictures so visitors don’t get overwhelmed. Please ensure the pictures are high quality.' },
                }, {
                  q: 'How often can I make changes?',
                  a: { __html: 'You can make 2 changes for free every calendar month. Contact us if you need more.' },
                }, {
                  q: 'Do you provide other services?',
                  a: { __html: 'We can also design logos, design social media starter kits, build any sort of websites, as well as provide general IT services. Simply contact us with your requirements.' },
                }, {
                  q: 'I have other questions not covered here.',
                  a: { __html: 'Simply <a href="mailto:' + email + '">send us an email</a>. We\'re always happy to help!' },
                }].map(d => (
                  <p className="my-5" key={d.q}>
                    <span className="has-text-weight-bold">
                      {d.q}
                    </span>
                    <br />
                    <span dangerouslySetInnerHTML={d.a} />
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactModal active={contactModalActive} onCloseClicked={closeContactModal} />

    </Layout>
  )
}

export const Head: HeadFC = ({ data }: HeadProps<Queries.IndexQuery>) => (
  <Seo title={data.site.siteMetadata.description} />
)

export default Index
