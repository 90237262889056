import * as React from "react"
import { useRef } from "react"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

const ContactModal = ({ active, onCloseClicked }: { active: boolean, onCloseClicked: Function, }) => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const formRef: React.MutableRefObject<HTMLFormElement | null> = useRef(null)

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (formRef && formRef.current) {
      formRef.current.submit();
    }
  }

  return (
    <div className={`modal ${active ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={() => onCloseClicked()}></div>
      <div className="modal-card mx-2" style={{ overflow: 'auto', maxHeight: 'calc(100vh - 20px)', maxWidth: 'calc(100vw - 20px)', }}>
        <header className="modal-card-head">
          <p className="modal-card-title">Contact</p>
          <button className="delete" aria-label="close" onClick={() => onCloseClicked()}></button>
        </header>
        <form ref={formRef} data-static-form-name="contact" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <section className="modal-card-body">
            <div className="field">
              <h4 className="title">Ready to get started? We'll get back to you within 24 hours.</h4>
              <p>Alternatively you can send us an email at <a href="mailto:info@web4biz.co.uk">info@web4biz.co.uk</a></p>
            </div>
            <div className="field">
              <label className="label">Contact Name</label>
              <div className="control">
                <input className="input" type="text" {...register("contact", { required: 'Contact name is required', })} placeholder="Your full name" />
              </div>
              {errors.contact && <p className="help is-danger">{errors.contact.message}</p>}
            </div>
            <div className="field">
              <label className="label">Business Name</label>
              <div className="control">
                <input className="input" type="text" {...register("business", { required: 'Business name is required', })} placeholder="Name of your business" />
              </div>
              {errors.business && <p className="help is-danger">{errors.business.message}</p>}
            </div>
            <div className="field">
              <label className="label">Email address</label>
              <div className="control">
                <input className="input" type="email" {...register("email", { required: 'Email address is required', })} />
              </div>
              {errors.email && <p className="help is-danger">{errors.email.message}</p>}
            </div>
            <div className="field">
              <label className="label">Message</label>
              <div className="control">
                <textarea className="textarea" name="message" placeholder="Tell us your requirements" />
              </div>
            </div>
            <input type="hidden" name="static-form-name" value="contact"></input>
            <div className="is-hidden field">
              <label className="label">Handle</label>
              <div className="control">
                <input className="input" type="text" name="handle" placeholder="Your handle" />
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button type="submit" className="button is-success is-large is-fullwidth">Send</button>
          </footer>
        </form>
      </div>
    </div >
  )
}

export default ContactModal
