import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

// import logo from "../images/logo-s.png"

interface NavbarProps {
  onContactClicked: Function,
}

const Navbar = ({ onContactClicked }: NavbarProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            email
          }
        }
      }
    `
  )

  return (
    <nav className="navbar is-spaced hero-background-color">
      <div className="navbar-brand">
        <a className="navbar-item" href="#">
          <StaticImage src='../images/logo.png' alt='logo' placeholder="none" loading="eager" />
        </a>
        {/* <div className="navbar-burger" data-target="navbarTop">
          <span></span>
          <span></span>
          <span></span>
        </div> */}
        <div className="navbar-item is-hidden-desktop ml-auto">
          <div className="buttons">
            <a onClick={() => onContactClicked()} className="button is-small is-info has-text-success has-text-weight-bold">
              GET IN TOUCH
            </a>
          </div>
        </div>
      </div>

      <div id="navbarTop" className="navbar-menu">
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <a onClick={() => onContactClicked()} className="button is-info has-text-success has-text-weight-bold">
                GET IN TOUCH
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
